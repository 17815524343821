import { fileAdapter } from '@/adapters/constructor/utils/file';

export default ({ data }) => {
  return {
    title: data.title,
    text: data.text,
    files: data.files?.map(fileAdapter),
    showDate: data.files_show_date,
  };
};
